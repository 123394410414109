import { FormikProvider, useFormik } from "formik";
import { useCart, useShop, useUser } from "hooks";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clear } from "store/cart/actions";
import { API_ENDPOINTS, request } from "utilities";
import notification from "utilities/notification";
import { loadScript, toJson } from "utilities/str";
import DefaultAddressQuery from "./DefaultAddressQuery";

const OrderForm = () => {
  const navigate = useNavigate();
  const { items, subTotal, total, totalQuantity, discount, coupon } = useCart();
  const { isUserLoggedIn, user } = useUser();
  const { info: shop } = useShop();
  const dispatch = useDispatch();
  const { data: defaultAddress } = DefaultAddressQuery();
  const address = toJson(defaultAddress?.address);
  const isDisabled = isUserLoggedIn && totalQuantity > 0;
  const [payMethod, setPayMethod] = useState("razorPay");
  const [saleTax, setSaleTax] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  useEffect(() => {
    if (total > discount) {
      let mytotal = subTotal - discount;
      if (mytotal < 850) {
        setSaleTax(0);
        let grand = mytotal + 0;
        setGrandTotal(grand > 0 ? grand : 0);
      } else {
        setSaleTax(0);
        setGrandTotal(mytotal);
      }
    } else {
      setSaleTax(0);
      setGrandTotal(0);
    }
  }, [total, discount, subTotal]);

  const initialValues = {
    products: items.map((item) => ({
      order_quantity: item?.quantity,
      product_id: item?.product_id,
      unit_price: item?.price,
      subtotal: item?.price * item?.quantity,
    })),
    status: 1,
    amount: total,
    sales_tax: saleTax,
    coupon_id: coupon?.id,
    shop_id: shop?.id,
    paid_total: grandTotal,
    total: total,
    discount: discount,
    coupan: coupon,
    customer_contact: user?.contact || "0123456789",
    customer_id: user?.id,
    customer_email: user?.email,
    payment_gateway: payMethod,
    billing_address: {
      country: address?.country,
      state: address?.state,
      zip: address?.zip,
      city: address?.city,
      address: address?.address,
      phone: address?.phone || "",
      email: address?.email || "",
      first_name: defaultAddress?.first_name || "",
      last_name: defaultAddress?.last_name || "",

    },
    shipping_address: {
      country: address?.country,
      state: address?.state,
      zip: address?.zip,
      city: address?.city,
      address: address?.address,
      phone: address?.phone || "",
      email: address?.email || "",
      first_name: defaultAddress?.first_name || "",
      last_name: defaultAddress?.last_name || "",
    },
  };

  const validateAddress = (address) => {
    if (
      !address?.country ||
      !address?.state ||
      !address?.zip ||
      !address?.city ||
      !address?.address ||
      !address?.phone ||
      !address?.email ||
      !defaultAddress?.first_name ||
      !defaultAddress?.last_name
    ) {
      return false;
    }
    return true;
  };


  const handleProceedClick = () => {
    if (!validateAddress(address)) {
      notification("error", "Please fill in all the required address fields.");
      return;
    }
    formik.handleSubmit();
  };

  const onSubmit = async (values) => {
    if (payMethod === "razorPay") {
      displayRazorpay(values);
    } else {
      try {
        const response = await request.post(API_ENDPOINTS.ORDER, values);
        if (response?.success) {
          notification("success", response?.message);
          dispatch(clear());
          navigate(`/`);
        } else {
          notification("error", response?.message);
        }
      } catch (error) {
        console.error("Order submission error:", error);
        notification("error", "Failed to submit order.");
      }
    }
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit,
  });

  async function displayRazorpay(orderDetails) {
    try {
      const orderResponse = await request.post(API_ENDPOINTS.ORDER, {
        ...orderDetails,
        paymentStatus: "pending",
      });

      if (orderResponse && orderResponse.success) {
              
        const createdOrder = orderResponse.data;
        const res = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
          notification(
            "error",
            "Razorpay SDK failed to load. Please check your internet connection."
          );
          return;
        }

        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY,
          currency: process.env.REACT_APP_RAZORPAY_CURRENCY || "INR",
          amount: createdOrder.total * 100,
          name: "BEAUTE INDIA",
          description: `Order #${createdOrder.id}`,
          handler: async function (response) {
            if (response?.razorpay_payment_id) {
              try {
                const paymentData = {
                  paymentId: response.razorpay_payment_id,
                  amount: createdOrder.total * 100,
                  currency: "INR",
                };
                console.log(paymentData);
                const paymentResponse = await request.post(
                  API_ENDPOINTS.PAYMENT_VERIFY,
                  paymentData
                );
                const paymentResult =
                  typeof paymentResponse.data === "string"
                    ? JSON.parse(paymentResponse.data)
                    : paymentResponse.data;
                  
                    console.log(paymentResult?.status);
                if (paymentResult?.status == "captured") {
                  const updateOrderResponse = await request.post(
                    API_ENDPOINTS.UPDATE_ORDER_PAYMENT,
                    {
                      orderId: createdOrder.id,
                      paymentStatus: "completed",
                      paymentId: response.razorpay_payment_id,
                      amount: createdOrder.total * 100,
                    }
                  );

                  if (updateOrderResponse?.success) {
                    notification(
                      "success",
                      updateOrderResponse.message ||
                        "Payment updated successfully."
                    );
                    dispatch(clear());
                    navigate(`/`);
                  } else {
                    notification(
                      "error",
                      updateOrderResponse.message ||
                        "Failed to update order payment status."
                    );
                  }
                } else {
                  notification(
                    "error",
                    "Payment verification failed. Please try again."
                  );
                }
              } catch (error) {
                console.error("Payment verification error:", error);
                notification(
                  "error",
                  "An error occurred during payment verification. Please try again."
                );
              }
            } else {
              notification(
                "error",
                "Payment was not completed. Please try again."
              );
            }
          },
          prefill: {
            name: user?.name || "",
            email: user?.email || "",
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } else {
        notification(
          "error",
          orderResponse?.message || "Failed to create order."
        );
      }
    } catch (error) {
      console.error("Order creation error:", error);
      notification(
        "error",
        "An error occurred while creating the order. Please try again."
      );
    }
  }

  return (
    <Fragment>
      <FormikProvider value={formik}>
        <div className="payment-option-section">
          <div className="row pt-5 pb-5">
            <div className="col-lg-8">
              <h2 className="payment">Payment Options</h2>
              <div className="choose-payment-mode mt-5 pe-5">
                <ul>
                  <div className="radio-btn-section pe-3">
                    <li>Razorpay</li>
                    <div className="form-check">
                      <label className="form-check-label" htmlFor="razorPay">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="payment_gateway"
                          id="razorPayOption"
                          value="razorPay"
                          checked={payMethod === "razorPay"}
                          onChange={(e) => setPayMethod(e.currentTarget.value)}
                        />
                      </label>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="total-section">
                <h1 style={{ fontSize: "26px" }} className="mb-5">
                  <u>Total</u>
                </h1>
                <div className="total-products">
                  {items.map((item, idx) => (
                    <div className="product-price" key={idx}>
                      <h5 style={{ fontSize: "18px" }}>{item?.model?.name}</h5>
                      <span style={{ fontSize: "18px" }}>
                        ₹ {item?.price * item?.quantity}
                      </span>
                    </div>
                  ))}
                  <div className="product-price">
                    <h5>Discount</h5>
                    <span>₹{discount}</span>
                  </div>
                  <div className="product-price">
                    <h5>Sub Total</h5>
                    <span>₹ {grandTotal}</span>
                  </div>
                  <p>Shipping Charges ₹ {saleTax}</p>
                </div>
                <button
                  type="button"
                  onClick={handleProceedClick}
                  disabled={!isDisabled}
                  className="payment-btn mt-5"
                >
                  Proceed to Razorpay Payment
                </button>
              </div>
            </div>
          </div>
        </div>
      </FormikProvider>
    </Fragment>
  );
};

export default OrderForm;
