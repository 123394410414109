import amazon from 'assets/pictures/Amazon Pay.png';
import logo from 'assets/pictures/header-logo.png';
import visacard from 'assets/images/visa.avif';
import mastercard from 'assets/images/master.avif';
import maestrocard from 'assets/images/Maestro.avif';
import amexcard from 'assets/images/Amex.avif';
import amazoncard from 'assets/images/Amazon-Pay.avif';
import paytmcard from 'assets/images/paytm.avif';
import upicard from 'assets/images/upi.avif';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Fragment, useState } from "react";
import { API_ENDPOINTS, request } from 'utilities';
import notification from 'utilities/notification';

function Footer({ category }) {
	let [searchParams] = useSearchParams();
	const [email, setEmail] = useState('');

	const handleNavigate = (e, key, value) => {
		const Obj = {};
		for (const [paramKey, paramValue] of searchParams) {
			if (paramKey === "price") {
				Object.assign(Obj, { price_min: paramValue[0], price_max: paramValue[1] });
			} else {
				Object.assign(Obj, { [paramKey]: paramValue });
			}
		}
		if (key === "price") {
			Object.assign(Obj, { price_min: value[0], price_max: value[1] });
		} else {
			Object.assign(Obj, { [key]: value });
		}
		const query = new URLSearchParams(Obj).toString();
		window.location.href = `/products?${query}`;
	};

	return (
		<Fragment>
			<div className="container-fluid footsection">
				<div className="main-footer-section py-4">
					<div className="row pt-5 pb-5">
						<div className="col-lg-4 col-md-4">
							<div className="footer-logo-section">
								<img src={logo} alt="Logo" />
								<p>
									Luxeoutlet has redefined conventional shopping experience with a brand portfolio
									that represents the pinnacle of craftsmanship and unsurpassed quality, further
									reinforcing its position as India's most desirable destination for world-class brands.
								</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4">
							<div className="footdata">
								<ul>
									<li>
										<div className="foothead">Quick Links</div>
									</li>
									<li><Link to="/who-we-are" target="_blank">Who we are?</Link></li>
									<li><Link to="/terms-condition" target="_blank">Terms & Conditions</Link></li>
									<li><Link to="/privacy-policy" target="_blank">We respect your privacy</Link></li>
									<li><Link to="/Contact" target="_blank">Contact Us</Link></li>
									<li><Link to="/Shipping" target="_blank">Shipping & Returns</Link></li>
									<li><Link to="/faqs" target="_blank">FAQs</Link></li>
								</ul>
							</div>
						</div>
						<div className="col-lg-4 col-md-4">
							<div className="footdata">
								<ul>
									<li>
										<div className="foothead mb-3">SHOP BY</div>
									</li>
									<div className="scroll-div">
										{Object.keys(category).map((name, idx) => (
											<li
												className="cat-filt category"
												style={{ cursor: "pointer" }}
												key={idx}
											>
												<a onClick={(e) => handleNavigate(e, "category", category[name])}>
													{name}
												</a>
											</li>
										))}
									</div>
								</ul>
							</div>
						</div>
					</div>

					<div className="container">
						<h6 className="text-center">CHECKOUT SECURELY WITH</h6>
						<div className="d-flex flex-wrap justify-content-center my-3 gap-3 img-card">
							<img src={visacard} alt="Visa" />
							<img src={mastercard} alt="Mastercard" />
							<img src={maestrocard} alt="Maestro" />
							<img src={amexcard} alt="Amex" />
							<img src={amazoncard} alt="Amazon Pay" />
							<img src={paytmcard} alt="Paytm" />
							<img src={upicard} alt="UPI" />
						</div>
						<h6 className="text-center mt-4">Copyright © 2023 Luxeoutlet. All rights reserved.</h6>
					</div>
				</div>
			</div>
		</Fragment>
	);
}

export default Footer;
